!function() {
    if ( !! swiffyslider ) document.addEventListener('load', () => swiffyslider.init() );

    const menuItems = document.querySelectorAll('ul.menu > li');
    const openSubMenu = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        const subMenu = evt.target.closest('li').querySelector('.sub-menu');

        if ( subMenu.classList.contains('show') ) return subMenu.classList.remove('show');

        const checkOffsetClick = evt => {
            if ( subMenu.contains( evt.target ) ) return true;
            
            subMenu.classList.remove('show');
            document.body.removeEventListener('click', checkOffsetClick);
        }

        subMenu.classList.add('show');
        document.body.addEventListener('click', checkOffsetClick );
    }
    menuItems.forEach( item => {
        if ( ! item.querySelector('.sub-menu') ) return;

        item.querySelector('a').addEventListener('click', openSubMenu);
    });
}();